<template>
  <div class="layout-box w-full h-full mx-auto overflow-hidden absolute z-50 left-[50%] transform translate-x-[-50%] bg-$color-fff <xl:max-w-[480px] <xl:pt-[200px] <xl:pb-[100px] xl:w-330 xl:h-200 xl:pt-25 xl:pb-20 xl:rounded-[26PX] flex flex-col justify-around items-center">
    <div class="flex flex-col items-center justify-center flex-1" v-if="props.pageStatus === 'loading'">
      <div class="relative flex xl:w-47.5 xl:h-47.5 <xl:w-[120px] <xl:h-[120px] items-center justify-center">
        <div class="logo-box w-9/10 h-9/10 rounded-1/2 flex items-center justify-center">
          <img class="w-71/100" src="../assets/logo_m.png" alt="">
        </div>
        <div class="absolute w-full h-full animate-spin1">
          <div class="loop xl:border-[5PX] <xl:border-[2PX]"></div>
          <div class="loop2 xl:border-[5PX] <xl:border-[2PX]"></div>
        </div>
        <div class="absolute w-11/10 h-11/10 animate-spin2">
          <div class="loop3 xl:border-[5PX] <xl:border-[2PX]"></div>
          <div class="loop4 xl:border-[5PX] <xl:border-[2PX]"></div>
        </div>
      </div>
      <span class="xl:text-xl xl:pt-15 <xl:text-[20px] text-[#010088] pt-[100px] font-normal">
        Is going to the CheckOut page...
      </span>
    </div>
    <div class="flex flex-col items-center justify-evenly flex-1 <xl:mx-4" v-else>
      <h2 class="xl:text-3xl <xl:text-[24px] text-center font-medium">
        Page not found, please try again
      </h2>
      <img class="xl:w-27 xl:h-21.5 <xl:w-[108PX] <xl:h-[86PX]" src="../assets/icon_expired.png" alt="">
      <div @click="tryAgain" class="xl:w-60 xl:h-15 xl:text-xl <xl:w-full <xl:h-[60px] <xl:text-[18px] rounded-[26PX] border-$theme-color text-$theme-color border flex items-center justify-center">Try again</div>
    </div>
    <footer class="flex items-center justify-center">
      <span class="xl:text-lg <xl:text-[18px] footer-text">Secured by</span>
      <img class="xl:w-28.5 xl:h-6.5 <xl:w-[114px] <xl:h-[26px]" src="../assets/logo_colour_mpurse.png" alt="">
    </footer>
  </div>
</template>
<script setup>
  const props = defineProps({
    pageStatus: {
      type: String,
      default: 'loading'
    },
  });
  const emit = defineEmits(['login']);
  const tryAgain = ()=>{
    emit('login')
  }
</script>
<style>
.footer-text{
  color: rgba(0, 0, 0, 0.8);
}
.logo-box{
  background-color: rgba(1, 0, 136, 1);
}
.loop{
  position: absolute;
  width: 100%;
  height: 100%;
  clip-path: polygon(0 0, 75% 0, 75% 75%, 0 75%);
  /* clip: rect(auto, 150PX, 150PX, auto); */
  border-radius: 50%;
  border-style: solid;
  border-color: rgba(1, 0, 136, 1);
}
.loop2{
  position: absolute;
  width: 100%;
  height: 100%;
  clip-path: polygon(25% 25%, 100% 25%, 100% 100%, 25% 100%);
  /* clip: rect(50PX, auto, auto, 50PX); */
  border-radius: 50%;
  border-style: solid;
  border-color: rgba(1, 0, 136, 1);
}
.loop3{
  position: absolute;
  width: 100%;
  height: 100%;
  /* clip: rect(auto, 55PX, 155PX, auto); */
  clip-path: polygon(0 0, 25% 0, 25% 75%, 0 75%);
  border-radius: 50%;
  border-style: solid;
  border-color: rgba(1, 0, 136, 0.2);
}
.loop4{
  position: absolute;
  width: 100%;
  height: 100%;
  clip-path: polygon(75% 25%, 100% 25%, 100% 100%, 75% 100%);
  /* clip: rect(55PX, auto, auto, 155PX); */
  border-radius: 50%;
  border-style: solid;
  border-color: rgba(1, 0, 136, 0.2);
}
@keyframes spin1 {
  0%,100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}
@-webkit-keyframes spin1 {
  0%,100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}
.animate-spin1 {
  -webkit-animation: spin1 3s ease-in-out infinite;
  animation: spin1 3s ease-in-out infinite;
}
@keyframes spin2 {
  0%,100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}
@-webkit-keyframes spin2 {
  0%,100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}
.animate-spin2 {
  -webkit-animation: spin2 3s ease-in-out infinite;
  animation: spin2 3s ease-in-out infinite;
}
</style>
