import {Cell, CellGroup, CountDown, Dialog, Field, Loading, Popup, PullRefresh, Step, Steps, Toast, Icon} from "vant";
import {getQuery} from "./utils/utils";
import {createApp} from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import 'windi.css'
import 'amfe-flexible'
let onetimeId = getQuery('onetimeId');
let onetimeIdStorage = sessionStorage.getItem("onetimeId");
if (onetimeIdStorage && onetimeIdStorage !== onetimeId) {
    sessionStorage.clear();
}
sessionStorage.setItem("onetimeId", onetimeId);
document.getElementById("expire").style.display = "none";
document.getElementById("app").style.visibility = "visible";

const vm = createApp(App);
[
    Popup,
    Toast,
    PullRefresh,
    Loading,
    Field,
    Step,
    Steps,
    CountDown,
    Dialog,
    Cell,
    CellGroup,
    Icon
].map((comp) => vm.use(comp));

vm.use(store)
  .use(router)
  .mount("#app");


