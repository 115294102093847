export function getQuery(name) {
    let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    let r = window.location.search.substr(1).match(reg);
    if (r != null) {
        return decodeURIComponent(r[2]);
    }
    return null;
}
function getRGB(color) {
  if (color.startsWith('#')) {
    color = color.substring(1); // 去掉#
    let r = parseInt(color.substring(0, 2), 16); // 转换红色
    let g = parseInt(color.substring(2, 4), 16); // 转换绿色
    let b = parseInt(color.substring(4, 6), 16); // 转换蓝色
    return { r, g, b };
  } else if (color.startsWith('rgb')) {
    const matches = color.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
    return matches ? {
      r: parseInt(matches[1], 10),
      g: parseInt(matches[2], 10),
      b: parseInt(matches[3], 10)
    } : null;
  }
  return null; // 不是有效的颜色格式
}
export function setColor(){
  let color = getQuery('theme');
  let rgb = color && getRGB(color);
  rgb && document.getElementsByTagName('body')[0].style.setProperty('--theme', `${rgb.r}, ${rgb.g}, ${rgb.b}`);
}
export function openNewWindow(url){
  let l = (screen.availWidth-1300) / 2;
  let t = (screen.availHeight-800) / 2;  
  let newWindow = window.open('', "_blank", `width=1300,height=800,top = ${ t > 0 ? t : 0 }, left= ${ l > 0 ? l : 0 }`)
  setTimeout(() => {newWindow.location = url}, 500)
  if( !newWindow || newWindow.closed || typeof newWindow.closed == 'undefined'){
    // ios设备打开链接
    window.location.replace(url);
  }
}

