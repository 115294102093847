// cssVar.ts
import { reactive } from "vue";
 
export function useCssVar(varName, initialValue) {
  const cssVars = reactive({
    [varName]: initialValue,
  });
 
  const setCssVar = (varName, value) => {
    cssVars[varName] = value;
    document.documentElement.style.setProperty(varName, value);
  };
 
  return {
    cssVars,
    setCssVar,
  };
}
/**
 * 将16进制的色值，根据透明度换算为新的颜色值
 * @param color 16位的色值
 * @param opacity 透明度
 * @param isRgbNumber 是否为rgba色值
 */
export const newColorWithOpacity = (color, opacity, isRgbNumber = false) => {
  const hex = color.replace(/^#/, "");
  const r = parseInt(hex.substr(0, 2), 16);
  const g = parseInt(hex.substr(2, 2), 16);
  const b = parseInt(hex.substr(4, 2), 16);
 
  // 计算新的RGB值
  const newR = Math.round((1 - opacity) * 255 + opacity * r);
  const newG = Math.round((1 - opacity) * 255 + opacity * g);
  const newB = Math.round((1 - opacity) * 255 + opacity * b);
 
  // 将新的RGB值转换为16位颜色值
  const newColor = `#${newR.toString(16).padStart(2, "0")}${newG.toString(16).padStart(2, "0")}${newB
    .toString(16)
    .padStart(2, "0")}`;
 
  return isRgbNumber ? `${newR},${newG},${newB}` : newColor;
};

const themConfig = {
  '--theme-color': 1,
  '--van-count-down-text-color': 1,
  '--theme-color-light-9': 0.9,
  '--theme-color-light-8': 0.8,
  '--theme-color-light-6': 0.6,
  '--theme-color-light-5': 0.5,
  '--theme-color-light-2': 0.2,
  '--theme-color-light-1': 0.1,
  '--theme-color-light-08': 0.08,
  '--theme-color-light-05': 0.05,
  '--theme-button-active-color': 0.9,
}
const themeColor = '#010088'
const { setCssVar } = useCssVar('--el-color', themeColor);

export const onChange = (val, isRgb) => {
  Object.keys(themConfig).forEach(e => {
    if (themConfig[e] === 1) {
      setCssVar(e, val)
      // setCssVar('--wi-color-primary', newColorWithOpacity(val, 1, isRgb))//透明度
    } else {
      const _color = newColorWithOpacity(val, themConfig[e],isRgb)
      setCssVar(e, _color)
    }
  })
}
onChange(themeColor)