<template>
  <transition-group name="fade">
    <PageLoad v-if="pageStatus !== 'onLoad'" :pageStatus="pageStatus" @login="login"></PageLoad>
  </transition-group>
  <div v-if="pageStatus ==='onLoad'" class="layout-box w-full h-full mx-auto overflow-hidden bg-$theme-color <xl:max-w-[480px] xl:w-330 xl:h-200 xl:p-2.5 xl:rounded-[26PX]">
    <div class="flex w-full h-full flex-col xl:flex-row">
      <div class="header relative flex flex-col xl:m-8 xl:mr-10">
        <div class="title flex items-center flex xl:w-106 <xl:p-[20px]">
          <img
            :src="store.state.merchant.logo || homeImg"
            crossOrigin = 'anonymous'
            class="logo border border-[#FFF] <xl:w-[66px] <xl:h-[66px] <xl:rounded-[26px] xl:w-22.5 xl:h-22.5 xl:rounded-[26PX]"
            alt="logo"
          />
          <div class="name text-$color-fff flex-1 <xl:px-[10px] pl-4 hyphens-auto leading-normal text-ellipsis2">
            <p class="<xl:text-[18px] !<xl:leading-[26px] xl:text-lg ">{{ store.state.merchant.name }}</p>
          </div>
          <img :src="iconStatus" @click="close" class="w-[34px] h-[34px] xl:hidden" alt="">
        </div>
        <div class="amount-box w-full xl:w-106" :class="routePath === '/paymentResult' ? 'hidden-md' : ''">
          <div class="xl:mt-10 rounded-[26PX] bg-$color-fff <xl:rounded-b-none">
            <div class="<xl:px-[90px] xl:p-8">
              <div class="text-$theme-color .text-lg font-normal <xl:hidden">Price Summary</div>
              <div class="xl:text-3xl xl:pt-3 <xl:text-center <xl:text-[30px] <xl:pt-[40px]">
                <span>₹</span>
                <span class="font-medium xl:text-5xl <xl:leading-[80px]" :class="String(paymentData.payAmount).length > 8 ? '<xl:text-[36px]' : '<xl:text-[68px]'">
                  {{ paymentData.payAmount }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="logo flex items-center absolute left-0 bottom-0 <xl:hidden">
          <span class="font-normal text-$color-fff text-lg">Secured by</span>
          <img src="../assets/logo_mpurse.png" class="w-23 h-6.5 ml-2" alt="">
        </div>
      </div>
      <div class="main-container bg-[#fff] flex-1 rounded-[26PX] overflow-hidden <xl:rounded-none <xl:mt-[-1px]">
        <slot></slot>
      </div>
      <Footer class="xl:hidden"></Footer>
    </div>
  </div>
</template>

<script setup>
import {useRoute, useRouter} from "vue-router";
import Footer from "@/components/Footer.vue";
import PageLoad from "@/components/PageLoad.vue";
import {useStore} from "vuex";
import {getQuery} from "@/utils/utils";
import {paymentValidate, getPaymentResult, getPaymentConfig} from "@/api/payment";
import {onMounted, ref, reactive, computed} from "vue";
import openModal from '../utils/dialog';
import { onChange } from '../utils/cssVar';

const homeImg = require('../assets/icon_home.png');

const store = useStore();
const route = useRoute();
const router = useRouter();

const routePath = ref('/');
const fromPath = ref('/');

router.beforeEach((to, from, next) => {
  routePath.value = to.path;
  fromPath.value = from.path;
  next(); // 必须调用next()来resolve这个钩子
});

// 初始数据
let paymentData = reactive({
  'payAmount': "0",
  'appName': "",
  'appLogo': "",
  'redirectLink': "",
  'appThemeColor': "#010088",
});
// 页面加载....
let pageStatus = ref('loading');

//登录获取初始化数据
onMounted(() => {
  let sessionId = getQuery("sessionId");
  if(sessionId){
    sessionStorage.setItem("sessionId", sessionId);
    //获取订单状态
    getStatus();
    getConfig();
  } else{
    login()
  } 
});
let iconStatus = computed(() => {
  let close = require('../assets/icon_close.svg');
  let left = require('../assets/icon_left.svg');
  if(routePath.value === '/upi'){
    return left;
  }else{
    return close;
  }
});

const close = () => {
  if(routePath.value === '/upi'){
    router.replace({
      path: fromPath.value
    });
  }else{
    openModal({
      onCancel: () => {
          if(paymentData.redirectLink){
            // 有重定向地址跳转
            window.location.replace(paymentData.redirectLink);
          }else{
            // 无重定向返回 通过window.open方式打开关闭窗口
            const isOPen = window.opener && window.opener !== window
            isOPen && window.close();
            router.back()
          }
        },
      });
  }
};

// 获取交易数据
let login = () => {
  pageStatus.value = 'loading';
  paymentValidate({onetimeId: getQuery("onetimeId")}).then( data => {
    sessionStorage.setItem("sessionId", data.sessionId || '');
    //获取订单状态
    getStatus();
    getConfig();
  }).catch(err=>{
    // 延迟2s关闭加载动画
    setTimeout(()=>{
      pageStatus.value = 'error';
    },2000)
  })

};
let getConfig = () => {
  getPaymentConfig().then( async (data) => {
    paymentData = Object.assign(paymentData, data);
    store.commit("updateMchName", data.appName);
    store.commit("updateMchLogo", data.appLogo);
    //改变主题颜色 
    onChange(paymentData.appThemeColor);
  })
};
// 获取订单状态
let getStatus = () => {
  getPaymentResult().then( data => {
    paymentData.payAmount = data.totalAmount || 0;
    paymentData.redirectLink = data.redirectLink || '';
    paymentData.redirectLink && sessionStorage.setItem("redirectLink", paymentData.redirectLink);
    store.commit("updateMchAmount", paymentData.payAmount);
    let time = data.orderExpireDuration || 0;
    let status = data.status;
    // 非初始化状态到结果页
    if(status !== 'INIT'){
      router.replace({
        path: "/paymentResult"
      });
    }else if(time){
      // 非结果页 有倒计时
      let endDate = new Date().getTime()+time;
      store.commit("updateMchEndTime", endDate);
    }else if(!time){
      overTime();//失败异常提示
    }
    // 延迟2s关闭加载动画
    setTimeout(()=>{
      pageStatus.value = 'onLoad';
    },2000)
  }).catch( err=>{
    // 延迟2s关闭加载动画
    setTimeout(()=>{
      pageStatus.value = 'error';
    },2000)
  })
  
};
// 异常提示
const overTime = () => {
  openModal({
    title: 'The page has expired',
    content: '',
    type: 'alert',
    cancelText: 'Close',
    confirmText: '',
    onCancel: () => {
      // 无重定向返回 通过window.open方式打开关闭窗口
      const isOPen = window.opener && window.opener !== window
      isOPen && window.close();
      router.back()
    },
  })
}
</script>