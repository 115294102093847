<template>
    <div class="mask fixed left-0 right-0 top-0 bottom-0 bg-$black-color6 flex items-center justify-center">
      <div class="xl:px-15 xl:py-13.5 xl:w-162.5 xl:h-94 <xl:w-[390px] <xl:h-[450px] <xl:px-[24px] <xl:py-[45px] rounded-[26PX] bg-$color-fff flex flex-col items-center justify-between">
        <Loading class="mt-4" v-if="props.type === 'loading'"></Loading>
        <h2 class="xl:text-3xl <xl:text-[32px] text-center font-medium" v-if="props.title">{{ props.title }}</h2>
        <p class="xl:text-2xl <xl:text-[18px] !leading-loose font-normal text-center" v-if="props.content">
          {{ props.content }}
        </p>
        <img v-else class="xl:w-27 xl:h-21.5 <xl:w-[108px] <xl:h-[86px]" src="../assets/icon_expired.png" alt="">
        <div class="flex w-full justify-between items-center xl:space-x-12 xl:flex-row <xl:space-y-2 <xl:flex-col">
          <button class="xl:w-60 xl:h-15 xl:text-xl xl:flex-1 <xl:w-full <xl:h-[60px] <xl:text-[18px] rounded-3xl border-$theme-color text-$theme-color border" v-if="props.cancelText" @click="cancelCall">{{ props.cancelText}}</button>
          <button class="xl:w-60 xl:h-15 xl:text-xl xl:flex-1 <xl:w-full <xl:h-[60px] <xl:text-[18px] rounded-3xl bg-$theme-color border-$theme-color text-$color-fff border" v-if="props.confirmText" @click="confirmCall">{{ props.confirmText}}</button>
        </div>
      </div>
    </div>
</template>

<script setup>
import Loading from "./Loading";
  const props = defineProps({
    title: {
      type: String,
      default: 'CANCEL PAYMENT?'
    },
    content: {
      type: String,
      default: 'Your payment is ongoing. Are you sure you want to cancel the payment?'
    },
    type:{
      type: String,//Confirm,alert,loading
      default: 'confirm'
    },
    cancelText: {
      type: String,
      default: ''
    },
    confirmText: {
      type: String,
      default: ''
    },
    cancelCall: {
      type: Function,
      default: () => {
      }
    },
    confirmCall: {
      type: Function,
      default: () => {
      }
    },
  });
</script>
