<template>
  <div class="count-down font-light text-$theme-color flex justify-center items-center xl:text-lg <xl:text-[16px]" v-if="time > 0">
    <span>PAGE EXPIRES IN &nbsp;</span>
    <span class="font-medium text-$theme-color xl:text-xl <xl:text-[20px]">
      <van-count-down
        :time="time"
        @finish="onFinish"
      />
    </span>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";
import openModal from '../utils/dialog';
import { useStore } from "vuex";
const store = useStore();
const router = useRouter();
const time = ref(0);
const endTime = store.state.merchant.endTime;
const nowTime = new Date().getTime();
time.value = endTime - nowTime;
const onFinish = ()=>{
  openModal({
    title: 'The page has expired',
    content: '',
    type: 'alert',
    cancelText: 'Close',
    confirmText: '',
    onCancel: () => {
      let redirectLink = sessionStorage.getItem("redirectLink")
      if(redirectLink){
        // 有重定向地址跳转
        window.location.replace(redirectLink);
      }else{
        // 无重定向返回 通过window.open方式打开关闭窗口
        const isOPen = window.opener && window.opener !== window
        isOPen && window.close();
        router.back()
      }
    },
  })
}
</script>
<style>
.van-count-down{
  font-size: 1em;
}
</style>
